// 拿来分析组件的配置信息
const tableKeys = ['tableHeader', 'tableData']
function findContainerKey(keys, containerKeys) {
  for (let key of keys) {
    if (containerKeys.indexOf(key) > -1) {
      return true
    }
  }
  return false
}
export default {
  methods: {
    /** 根据组件的配置信息 判断出是那种类型的组件
     * @param  {} config
     */
    analysisComType(config) {
      var keys = Object.keys(config)
      if (findContainerKey(keys, tableKeys)) return 'table'
      return 'echarts'
    },
    extractComTitile(com, comType) {
      if (comType === 'table') {
        return com.page ? com.page.title : ''
      } else if (comType === 'echarts') {
        if (com.title) {
          let title = com.title.text ? com.title.text : ''
          com.title.text = ''
          return title
        }
        return ''
      }
    }
  }
}
