/* eslint-disable no-console */
var uri = 'data:application/vnd.ms-excel;base64,';
var template = '<html><head><meta charset="UTF-8"></head><body><table border="1">{table}</table></body></html>';
var base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) };
var format = function (s, c) {
    return s.replace(/{(\w+)}/g,
        function (m, p) { return c[p]; })
}
export function table2Excel(table, name) {
    if (!table.nodeType) table = document.getElementById(table)
    var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML }
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.href = uri + base64(format(template, ctx));
    a.download = name;
    a.click();
    document.body.removeChild(a);
}
export function tableToExcel(node, title = document.title) {
    if (node) {
        var tables = node.querySelectorAll('table');
        var thead = tables[0].querySelector('thead').cloneNode(true);
        var ths = thead.querySelectorAll('th');
        for (var i = 0, len = ths.length; i < len; i++) {
            if (!ths[i].innerHTML) {
                ths[i].parentNode.removeChild(ths[i]);
                len--;
                i--;
            }
        }
        var body = tables[1].querySelector('tbody').cloneNode(true);
        var trs = body.querySelectorAll('tr');
        for (var ii = 0, iLen = trs.length; ii < iLen; ii++) {
            var tds = trs[ii].querySelectorAll('td');
            for (var j = 0, jLen = tds.length; j < jLen; j++) {
                var text = tds[j].querySelectorAll('.xa-cell--text');
                if (text.length === 0) {
                    text = tds[j].querySelectorAll('.cell')
                }
                if (text.length === 0) {
                    tds[j].innerText = '';
                } else {
                    tds[j].innerText = text[0].innerText;
                }
            }
        }
        var tablesNode = document.createElement('table');
        tablesNode.appendChild(thead);
        tablesNode.appendChild(body);
        try {
            table2Excel(tablesNode, title + '.xls');
        } catch (e) {
            window.alert('不支持');
        }
    }
}