<template>
  <el-col
    class="cell-wrap"
    :xs="viewSize.xs"
    :sm="viewSize.sm"
    :md="viewSize.md"
    :lg="viewSize.lg"
  >
    <header class="panel__hd" :class="{ 'panel__hd--margin': !display }">
      <div class="xa-cell">
        <div class="xa-flex">{{ title }}</div>
        <div class="xa-cell">
          <template v-if="display">
            <slot name="toolBtn"></slot>
            <el-button
              @click="tableToExcel"
              v-if="type == 'table'"
              type="primary"
              size="small"
              icon="el-icon-download"
            >
            </el-button>
            <el-button
              @click="changelg"
              class="media-display media-display--lg media-noDisplay--md media-noDisplay--sm"
              type="primary"
              size="small"
              icon="el-icon-rank"
            >
            </el-button>
          </template>
          <el-button @click="toggleDisplay" type="primary" size="small">
            <i
              class="iconfont"
              style="vertical-align: middle;line-height: 10px;"
              :class="{
                'icon-yanjing': display,
                'icon-yanjing2 icon-disvisble': !display
              }"
            ></i>
          </el-button>
        </div>
      </div>
    </header>
    <section class="content-wrap">
      <div v-show="display">
        <slot :changeView="changeViseSize" :display="display"></slot>
      </div>
    </section>
  </el-col>
</template>
<script>
import { tableToExcel } from '@/mixins/table2Excel.js'
export default {
  props: {
    title: String,
    type: String
  },
  data() {
    return {
      viewSize: {
        xs: 24,
        sm: 24,
        md: 12,
        lg: 12
      },
      changeViseSize: 0,
      display: true
    }
  },
  methods: {
    tableToExcel() {
      tableToExcel(this.$el, this.title)
    },
    changelg() {
      this.viewSize.lg = (this.viewSize.lg % 24) + 12
      this.changeViseSize++
    },
    toggleDisplay() {
      this.display = !this.display
    }
  }
}
</script>
<style scoped>
.cell-wrap {
  margin-bottom: 4px;
}
.content-wrap {
  overflow: hidden;
  background: #fff;
}
.panel__hd {
  background: #fff;
  border-radius: 5px 5px 0 0;
  position: relative;
  text-align: left;
  padding: 5px 10px;
}

.panel__hd::after {
  content: ' ';
  position: absolute;
  height: 1px;
  background: rgb(235, 238, 245);
  bottom: -1px;
  left: 10px;
  right: 10px;
  transform: scaleY(0.5);
}

.panel__hd--margin {
  border-radius: 4px;
  overflow: hidden;
}
</style>
