<template>
  <keep-alive :include="include">
    <components :is="currentView" :isChild="true" />
  </keep-alive>
</template>
<script>
import dashBoard from './dashBoard.vue'
export default {
  name: 'diyDashBoard',
  data() {
    return {
      currentView: '',
      isActivated: true,
      includeList: [''],
      cancelCacheKey: ''
    }
  },
  computed: {
    include() {
      return this.includeList.join(',')
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, oldVal) {
        this.$nextTick(function() {
          if (this.isActivated === false) return
          if (!oldVal || val.query.src !== oldVal.query.src)
            val.query.src && this.changeView(val.query.src, val, oldVal)
        })
      }
    }
  },
  methods: {
    buildComponents(name, baseComs) {
      if (this.$options.components[name]) return
      this.$options.components[name] = () =>
        Promise.resolve({
          ...baseComs,
          name
        })
    },
    getRightComName(name, to) {
      name =
        'board' +
        name.replace(/\//g, '_') +
        '_' +
        JSON.stringify(to.query.params)
      return name.replace(/\W/g, '')
    },
    async changeView(name, to) {
      if (!name) return
      name = this.getRightComName(name, to)
      await this.buildComponents(name, dashBoard)
      this.$nextTick(function() {
        this.currentView = name
      })
    }
  },
  activated() {
    this.isActivated = true
  },
  deactivated() {
    this.isActivated = false
  }
}
</script>
