<template>
  <el-container class="dashBoard-page xa-container">
    <el-header height="initial">
      <FilterBarHead
        :title="page.title"
        :filterConfig="filterConfig"
        :filterResult="filterResult"
        :showSwitchBtn="filterConfig.length!=0"
        @filterBarResultChange="onFilterBarResultChange"
      >
        <div slot="title-fr">
          <el-button
            @click="onRefleshClick"
            type="primary"
            size="small"
            :icon="loading ? 'el-icon-loading' : 'el-icon-refresh'"
            plain
          >
          </el-button>
        </div>
      </FilterBarHead>
    </el-header>
    <el-main>
      <AppLineLoading v-if="loading" />
      <el-row class="dashboard-wrap">
        <template v-for="panl in panls">
          <layoutCell
            v-if="panls.length"
            :size="panl.size"
            :title="panl.title"
            :key="panl.id"
            :type="panl.type"
          >
            <el-button
              @click="deleteItem(panl, panls)"
              slot="toolBtn"
              type="primary"
              icon="el-icon-delete"
              size="small"
            ></el-button>
            <template slot-scope="props">
              <div v-if="panl.type == 'echarts'" class="dashBoard-echarts">
                <echarts-component
                  :fullHeight="true"
                  :options="panl.config"
                  :changeView="props.changeView"
                  :display="props.display"
                />
              </div>
              <div v-if="panl.type == 'table'" class="dashBoard-table">
                <tableComponent
                  :tableHeader="panl.config.tableHeader"
                  :tableData="panl.config.tableData"
                  :selection="false"
                />
              </div>
            </template>
          </layoutCell>
        </template>
      </el-row>
    </el-main>
  </el-container>
</template>
<script>
/* eslint-disable */
import layoutCell from './layoutCell'
import componentsAnalysisMixins from '@/mixins/componentsAnalysis'
import titleFilterBarHeadMinxin from '@/mixins/titleFilterBarHeadMinxin.js'

export default {
  components: {
    layoutCell,
    echartsComponent: () => import('@/components/echarts'),
    tableComponent: () => import('@/components/xa-table/table/Index')
  },
  mixins: [componentsAnalysisMixins, titleFilterBarHeadMinxin],
  data() {
    return {
      loading: false,
      page: {
        title: ''
      },
      //  筛选的配置
      filterConfig: [],
      // 筛选的默认值 已经实时提交的筛选结果
      filterResult: {},
      panls: [],
      isActivated: true, // 该页面是否是激活
      src: '' // 将在页面初始化的时候获得 用于有过滤条件的时候
    }
  },
  methods: {
    fetchData(url, data) {
      return this.$diyAction(url, data).catch(error => {
        this.$alert(error.msg, '', {
          type: 'error',
          confirmButtonText: '确定'
        })
      })
    },
    initPageConfig({
      page = {},
      filterConfig = [],
      filterResult = {},
      src = '',
      items = []
    }) {
      this.page.title = page.title
      this.filterConfig = filterConfig
      this.filterResult = this.initFilterResultByConfig(
        filterConfig,
        filterResult
      )
      this.src = src
      this.displayPanelsCells(items)
    },
    init() {
      this.loading = true
      this.fetchData(
        this.$route.query.src,
        this.$route.query.params ? JSON.parse(this.$route.query.params) : {}
      ).then(data => this.initPageConfig(data))
    },
    deleteItem(item, items) {
      items.splice(items.indexOf(item), 1)
    },
    // 当筛选内容发生改变
    onFilterBarResultChange(filterBarResult) {
      var params = JSON.parse(JSON.stringify(filterBarResult))
      this.filterResult = filterBarResult
      this.loading = true
      this.fetchData(this.src, filterBarResult).then(data =>
        this.displayPanelsCells(data.items)
      )
    },
    onRefleshClick() {
      this.onFilterBarResultChange(this.filterResult)
    },
    displayPanelsCells(items) {
      this.$nextTick(function() {
        this.panls = items.map(item => {
          const comType = this.analysisComType(item)
          return {
            title: this.extractComTitile(item, comType),
            type: comType,
            id: parseInt(Math.random() * 1000),
            config: item
          }
        })
        this.loading = false
      })
    }
  },
  computed: {
    isDataEmpty() {
      var dataLength = this.panls.length
      if (this.loading === false && dataLength === 0) {
        return true
      }
      return false
    }
  },
  beforeMount() {
    this.init()
  },
  activated() {
    this.isActivated = true
  },
  deactivated() {
    this.isActivated = false
  }
}
</script>
<style lang="scss">
.dashBoard-page {
  & > .el-header,
  & > .el-main {
    padding: 0;
  }
  & > .el-header {
    padding-bottom: 4px;
  }
  .dashBoard-table,
  .dashBoard-echarts {
    height: 400px;
    padding: 8px 10px;
  }
  .dashboard-wrap {
  }
}
</style>
